import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  RefreshIcon,
  BellIcon,
  LogoutIcon,
  IconFlag,
  SortIcon,
} from "assets/icons.js";
import SampleLogo from "assets/Image54.svg";
import SampleLogomain from "assets/logo.png";
import SampleImage from "assets/placeholder-image.png";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form, Input, Formik } from "ui/forms";
import { UserContext } from "context/UserContext";
import { BasicButton } from "ui/Buttons";
import Notifications from "ui/Notifications";
import * as Yup from "yup";
import { applicationDate } from "utils/application-date";
import moment from "moment";
import { OverlayTrigger } from "react-bootstrap";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import { Route_URLs } from "routesURLs";
import { Popover } from "ui/Popover";
import jwt_decode from "jwt-decode";
import { LoginCustomerData, loginCustomerData } from "queries/actuals";
import { useEffect } from "react";
import { useRef } from "react";

const StyledNavBar = styled.nav`
  position: relative;
  width: 100%;
  background-color: #3a3b48;
  color: #ffffff;
  text-align: left;
  height: auto;
  overflow: auto;
  padding: 12px;
  margin: 15px 0;
  border-radius: 10px;
  li {
    text-decoration: none;
    list-style: none;
    float: right;
    font-size: 14px;
  }
  .refresh {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    font-family: Montserrat Alternates-Medium;
  }
  .profile-image {
    border-radius: 50%;
    height: 38px;
    width: 38px;
    margin-right: 15px;
  }
  .greetings {
    font-size: 18px;
    float: left;
    line-height: 22px;
    font-family: Montserrat Alternates-semiBold;
  }
  .logo-image {
    margin: 5px;
    height: 30px;
  }
  .accounts-data {
    border-left: 1px solid #707070;
    padding: 5px;
  }
`;

const StyledRefreshIcon = styled.div`
  display: inline-block;
  margin: 0px 18px 0px 12px;
`;

const NotificationDiv = styled.div`
  display: flex;
  border-bottom: 2px solid #707070;
  padding: 10px;
  .notifications-text {
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 20px;
    color: #909090;
    display: inline-block;
  }
  .flag {
    margin: 5px;
  }
`;
const ProfileDetails = styled.div`
  padding: 20px 15px;
  font-family: Montserrat Alternates;
  font-size: 11px;
  line-height: 20px;
  cursor: pointer;
  color: #909090;
  .reset-password {
    font-family: Montserrat Alternates-Bold;
    font-size: 11px;
    line-height: 20px;
    color: #33c2df;
  }
  input {
    border-radius: 0px !important;
    background: #232631 !important ;
    height: 25px !important;
  }
`;
const ResetPassword = styled.div``;
const StyledRadio = styled.div`
  img {
    height: 30px;
    margin: 20px 10px;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
    background-clip: content-box;
    padding: 4px;
    border: 2px solid #ffffff;
    &:checked {
      border: 4px solid #4edaf7;
      background-color: white;
      padding: 0px;

      &:after {
        background-color: white;
      }
    }
  }
`;
const LogoutDiv = styled.div`
  font-family: Montserrat Alternates-semiBold;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: #909090;
  width: 100%;

  svg {
    margin-right: 10px;
    path {
      fill: white;
      stroke: white;
    }
  }
`;

const Notificationpopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      {[...Array(3)].map((i) => (
        <NotificationDiv>
          <div>
            <IconFlag font-size="20px" className="flag" />
          </div>
          <div>
            <span className="notifications-text">
              Dispute 232323 raised by Ramesh with category Dispute Category on
              IN 2323232
            </span>
            <span className="notifications-text">20 min ago </span>
          </div>
        </NotificationDiv>
      ))}
      <Link to={Route_URLs.notifications} className="view-all">
        View All
      </Link>
    </Popover.Body>
  </Popover>
);

const NotificationDropdown = () => {
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={Notificationpopover}
      >
        {/* <Iconbackground> */}
        <BellIcon className="notification-icon" />
        {/* </Iconbackground> */}
      </OverlayTrigger>
    </>
  );
};

const ProfileDetailspopover = (onLogout, onLogoutSuperUser) => {
  let tokenData = sessionStorage.getItem("userToken");
  let history = useHistory();
  let decodedData = jwt_decode(tokenData);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [Errors, setErrors] = useState("");
  const value = useContext(UserContext);

  let logData = useRef({});

  let userId = decodedData.username;
  const { data: cardData, isLoading: iscardloading } = LoginCustomerData({
    userId,
  });
  // console.log("data---->",cardData)
  logData.current = cardData ? cardData.data : {};

  // useEffect(() => {
  //   if (cardData) {
  //     if (
  //       (cardData && !cardData?.data.billingAddress) ||
  //       Object.keys(cardData.data.billingAddress).length <= 0
  //     ) {
  //       history.push(Route_URLs.billingAddress);
  //     }
  //   }
  // }, [cardData]);

  return (
    <Popover id="popover-basic">
      <Popover.Body>
        <ProfileDetails>
          <p style={{ fontSize: "15px", color: "#FFFFFF" }}>
            {logData.current?.givenName?.concat(
              " ",
              logData.current.familyName
            )}
          </p>
          <p>{logData.current.email}</p>
          <p>
            If you’ve lost your password or wish to reset it use the link below
            to get started
          </p>
          <p
            onClick={() => setShowResetPassword(!showResetPassword)}
            className="reset-password"
          >
            Reset Password
          </p>
          {showResetPassword ? (
            <ResetPassword>
              <Formik
                initialValues={{
                  newPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={Yup.object().shape({
                  oldPassword: Yup.string().required(
                    "Please enter old password"
                  ),
                  newPassword: Yup.string()
                    .required("Please enter New Password")
                    .matches(
                      /^(?=.*[a-z])(?=.*\d)(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                    ),
                  confirmPassword: Yup.string()
                    .oneOf(
                      [Yup.ref("newPassword"), null],
                      "Passwords must match"
                    )
                    .required("Please enter valid password"),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  // console.log(values);
                  setSubmitting(true);
                  Auth.currentAuthenticatedUser()
                    .then((customer) => {
                      return Auth.changePassword(
                        customer,
                        values.oldPassword,
                        values.newPassword
                      );
                    })
                    .then((data) => {
                      // console.log("success", data);
                      setShowResetPassword(!showResetPassword);
                      window.flash(
                        "open",
                        "primary",
                        "Password successfully changed !"
                      );
                      setTimeout(() => {
                        window.flash("close", "primary");
                      }, 60000);
                    })
                    .catch((err) => {
                      // console.log("error", err);
                      //setErrors(err.message);
                      setShowResetPassword(!showResetPassword);
                    });
                }}
              >
                {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                  <Form className="mx-auto">
                    <ErrorHandlingComponent errors={Errors} />
                    <Input
                      name="oldPassword"
                      type="password"
                      placeholder="old Password"
                    />
                    <Input
                      name="newPassword"
                      type="password"
                      placeholder="New Password"
                    />
                    <Input
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                    />
                    <BasicButton
                      type="button"
                      width="140px"
                      style={{ float: "right", margin: "20px" }}
                      onClick={() => {
                        // console.log("clicked reset-pass");
                        handleSubmit();
                      }}
                      //disabled={!(isValid && dirty)}
                    >
                      Reset Password
                    </BasicButton>
                  </Form>
                )}
              </Formik>
            </ResetPassword>
          ) : (
            <>
              {sessionStorage.getItem("isSuperUser") == "true" ? (
                <LogoutDiv onClick={onLogoutSuperUser}>
                  <LogoutIcon font-size="20px" />
                  <span className="profile-details">Logout As Customer</span>
                </LogoutDiv>
              ) : (
                <LogoutDiv onClick={onLogout}>
                  <LogoutIcon font-size="20px" />
                  <span className="profile-details">Logout</span>
                </LogoutDiv>
              )}
            </>
          )}
        </ProfileDetails>
      </Popover.Body>
    </Popover>
  );
};
const ProfileDetailsDropdown = () => {
  const userContext = useContext(UserContext);

  const onLogout = async () => {
    try {
      await Auth.signOut();
      userContext.setUser(null);
      sessionStorage.clear();
      localStorage.clear();
      window.location.pathname = "/";
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  const onLogoutSuperUser = async () => {
    try {
      userContext.setUser(null);
      // localStorage.clear();
      // sessionStorage.clear();
      sessionStorage.removeItem("collapsed");
      sessionStorage.removeItem("tenantName");
      sessionStorage.removeItem("userToken");
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("client_id");
      sessionStorage.removeItem("tokendata");
      window.location.pathname = "/admin/login/customers";
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = SampleImage;
  };
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={ProfileDetailspopover(onLogout, onLogoutSuperUser)}
      >
        <img
          onError={addDefaultSrc}
          alt="profile"
          className="profile-image"
          src={SampleImage}
          // src={SampleImage}
        />
      </OverlayTrigger>
    </>
  );
};

const Logopopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <StyledRadio>
        <input type="radio" id="radio1" value="1" name="logo-image" />
        <img src={SampleLogo} alt="logo" />
      </StyledRadio>
      <StyledRadio>
        <input type="radio" id="radio2" value="2" name="logo-image" />
        <img src={SampleLogo} alt="logo" />
      </StyledRadio>
    </Popover.Body>
  </Popover>
);
const LogoDropdown = (dataset) => {
  return (
    <>
      {/* <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={Logopopover}
      >
        <div>
          <img className="logo-image" src={SampleLogomain} alt="logo" />
          <SortIcon font-size="10px" />
        </div>
      </OverlayTrigger> */}

      <div>
        <img
          className="logo-image"
          src={
            dataset.dataset.data.bucketUrl
              ? dataset.dataset.data.bucketUrl
              : SampleLogomain
          }
          alt="logo"
        />
        {/* <SortIcon font-size="10px" /> */}
      </div>
    </>
  );
};
const Navbar = ({ name }) => {
  let tokenData = sessionStorage.getItem("userToken");
  let history = useHistory();
  let decodedData = jwt_decode(tokenData);
  const userContext = useContext(UserContext);

  let userId = decodedData.username;
  const { data: cardData, isLoading: iscardloading } = LoginCustomerData({
    userId,
  });

  return (
    <StyledNavBar>
      <li className="greetings">
        {cardData && <LogoDropdown dataset={cardData} />}
      </li>
      <li className="refresh">
        <span>
          {/* Last refreshed on {moment(applicationDate).format("MM/DD/YYYY")} */}
          Last refreshed on {moment().format("MM/DD/YYYY")}
        </span>
        <StyledRefreshIcon>{/* <RefreshIcon /> */}</StyledRefreshIcon>
        {/* // <Notifications /> */}
        <ProfileDetailsDropdown />
      </li>
    </StyledNavBar>
  );
};
export default withRouter(Navbar);
