import { apiURL, awsExportUrl } from "utils/base-url";
import axios from "utils/APIv2";
import BaseService from "./baseClass.services";

class Operationservice extends BaseService {
  getTenantsForLogin = async () => {
    const res = await axios.get(`/operations/list/tenant`);
    return res.data;
  };

  getCustomersForLogin = async () => {
    const res = await axios.get(`/operations/list/customer`);
    return res.data;
  };

  getUsers = async () => {
    const res = await axios.get(`/operations/users`);
    return res.data;
  };

  getTenantUsers = async (tenantName) => {
    const res = await axios.get(`/operations/${tenantName}/users`);
    return res.data;
  };

  getInsights = async (tenantName) => {
    const res = await axios.get(`/operations/${tenantName}/insights`);
    return res.data;
  };

  getDailys = async (tenantName) => {
    const res = await axios.get(`/operations/${tenantName}/dailydetails`);
    return res.data;
  };

  getMetrics = async (tenantName) => {
    const res = await axios.get(`/operations/${tenantName}/metrics`);
    return res.data;
  };

  getReportData = async (tenantName) => {
    const res = await axios.get(`/operations/${tenantName}/reportData`);
    return res.data;
  };

  addTestPoc = async (tenantName, body) => {
    const res = await axios.post(
      `/operations/${tenantName}/updateTestPoc`,
      body
    );
    return res.data;
  };

  getCustomersForLogin = async () => {
    const res = await axios.get(`/operations/list/customer`);
    return res.data;
  };

  loginAsTenant = async (body) => {
    const res = await axios.post(`/operations/login/tenant`, body);
    return res.data;
  };

  loginAsCustomer = async (body) => {
    const res = await axios.post(`/operations/login/customer`, body);
    return res.data;
  };

  register = async (body) => {
    const res = await axios.post(`/operations/register`, body);
    return res.data;
  };

  verify = async (body) => {
    const res = await axios.post(`/operations/verify`, body);
    return res.data;
  };

  verifyOtp = async (body) => {
    const res = await axios.post(`/operations/verifyOtp`, body);
    return res.data;
  };

  activateTenant = async (body) => {
    const res = await axios.post(`/operations/activateTenant`, body);
    return res.data;
  };

  deActivateTenant = async (body) => {
    const res = await axios.post(`/operations/deActivateTenant`, body);
    return res.data;
  };

  deleteSuperUser = async (body) => {
    const res = await axios.post(`/operations/delete`, body);
    return res.data;
  };
  updatePaymentAccess = async (body) => {
    const res = await axios.post(`/operations/payment-access`, body);
    return res.data;
  };
  updateDataLoad = async (body) => {
    const res = await axios.post(`/operations/load`, body);
    return res.data;
  };

  reportDownloads = async (tenant, file) => {
    const res = await axios.get(
      `${apiURL}/operations/export?tenant=${tenant}&file=${file}`
    );
    return res.data.downloadUrl;
  };
}
export default new Operationservice();
