import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import Page from "ui/Page";
import PrivateLayout from "layouts/operations-ui/privateLayout";
import PerformanceTimeCard from "./performanceTimeCard";
import PerformanceCard from "./performanceCard";
import UserListingPerformance from "./userListingPerformance";
import PerformanceInsightsCard from "./PerformanceInsightsCard";
import ReportDownloadCard from "./ReportDownload";
import EventTable from "./eventTable";
import { InsightsIcon } from "assets/icons";
import SampleImage from "assets/placeholder-image.png";
import {
  useGetDaily,
  useGetInsights,
  useGetMetrics,
  useGetReportData,
  useGetTenantCustomers,
} from "queries/operations";
import moment from "moment";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Loader from "ui/Loader";
import Card from "ui/Card";

const DashboardWrapper = styled.div`
  .link-to-pages {
    text-decoration: none;
  }
`;
const Logo = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  height: 148px;
  background: #2b2b34 0% 0% no-repeat padding-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  .logo-heading {
    font-size: 11px;
    line-height: 14px;
    font-family: Montserrat Alternates-Bold;
    letter-spacing: 0px;
    color: #909090;
  }
  img {
    margin: 10%;
    width: 80%;
    height: 50%;
    border-radius: 5%;
  }
`;

const StyledList = styled.ul`
  li {
    word-break: break-word;
    font-style: normal;
    font-style: inherit;
    color: #ffffff;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    padding-bottom: 15px;
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;
const StyledInsightIcon = styled.div`
  margin-right: 10px;
  svg {
    font-size: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-right: 5px;
  }
`;

export default function Performance() {
  const [errorMessage, setErrorMessage] = useState("");
  const { param } = useParams();
  const { data: users, isLoading: isloading } = useGetTenantCustomers(param);
  let usersData = users ? users : [];

  const { data: value, isLoading: isloadingInsights } = useGetInsights(param);
  let valueData = value ? value : null;

  const { data: daily, isLoading: isloadingDaily } = useGetDaily(param);

  const { data: metrics, isLoading: isloadingMetric } = useGetMetrics(param);

  const { data: report, isLoading: isloadingReport } = useGetReportData(param);

  return (
    <>
      <DashboardWrapper>
        <PrivateLayout>
          <Page>
            <Page.Header>
              <Row>
                <Col sm={8}>
                  <h1 className="page-title">Analytics</h1>
                </Col>
              </Row>
            </Page.Header>
            <Page.Body>
              <ErrorHandlingComponent errors={errorMessage} />
              <Card>
                <Card.Body>
                  {isloadingInsights ? (
                    <Loader />
                  ) : (
                    <Row>
                      <Col sm={2}>
                        <Logo>
                          <img
                            src={
                              valueData?.logo
                                ? "https://simplicapital-live-bucket.s3.amazonaws.com/" +
                                  valueData?.logo
                                : SampleImage
                            }
                            alt="logo"
                            style={{ objectFit: "contain" }}
                          ></img>
                        </Logo>
                      </Col>
                      <Col sm={3} style={{ marginTop: "15px" }}>
                        <StyledList>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.receivableForecast?.text} */}
                            Onboarded on :{" "}
                            {moment(valueData?.onboardedOn).format(
                              "YYYY-MM-DD"
                            )}
                          </li>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.payable?.text} */}
                            Category : {valueData?.category}
                          </li>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            Timezone : {valueData?.timezone}
                          </li>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            Email address : {valueData?.autoEmailAddress}
                          </li>
                        </StyledList>
                      </Col>
                      <Col sm={3} style={{ marginTop: "15px" }}>
                        <StyledList>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            App Start Date :{" "}
                            {moment(valueData?.appStartDate).format(
                              "YYYY-MM-DD"
                            )}
                          </li>
                          <li data-tip data-for="erp">
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            ERP Details :{" "}
                            {valueData?.erpConnectionDetails?.name}
                          </li>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            Email Templates : {valueData?.emailTemplateCount}
                          </li>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            Bank Connects : {valueData?.bankConnectionCount}
                          </li>
                        </StyledList>
                      </Col>
                      <Col sm={3} style={{ marginTop: "15px" }}>
                        <StyledList>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            Database size :{" "}
                            {(
                              Number(valueData?.dbstats?.fileSize) /
                              1024 ** 3
                            ).toFixed(2)}{" "}
                            GB
                          </li>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            Db Collections : {valueData?.dbstats?.collections}
                          </li>
                          <li>
                            <StyledInsightIcon>
                              <InsightsIcon />
                            </StyledInsightIcon>
                            {/* {data.netcash?.text} */}
                            Business Owner : Deepak Ansari
                          </li>
                        </StyledList>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
              <Row>
                <Col md={9}>
                  <Row>
                    <Col md={6}>
                      <PerformanceTimeCard
                        isLoading={isloadingDaily}
                        date={
                          daily?.rdailies[daily?.rdailies.length - 1]
                            .dtReceivables
                        }
                        date2={
                          daily?.rdailies[daily?.rdailies.length - 2]
                            .dtReceivables
                        }
                        date3={
                          daily?.rdailies[daily?.rdailies.length - 3]
                            .dtReceivables
                        }
                        value={
                          daily?.rdailies[daily?.rdailies.length - 1].endBalance
                        }
                        value2={
                          daily?.rdailies[daily?.rdailies.length - 2].endBalance
                        }
                        value3={
                          daily?.rdailies[daily?.rdailies.length - 3].endBalance
                        }
                        title="Receivables"
                      />
                    </Col>
                    <Col md={6}>
                      <PerformanceTimeCard
                        isLoading={isloadingDaily}
                        date={
                          daily?.pdailies[daily?.pdailies.length - 1].dtPayables
                        }
                        date2={
                          daily?.pdailies[daily?.pdailies.length - 2].dtPayables
                        }
                        date3={
                          daily?.pdailies[daily?.pdailies.length - 3].dtPayables
                        }
                        value={
                          daily?.pdailies[daily?.pdailies.length - 1].endBalance
                        }
                        value2={
                          daily?.pdailies[daily?.pdailies.length - 2].endBalance
                        }
                        value3={
                          daily?.pdailies[daily?.pdailies.length - 3].endBalance
                        }
                        title="Payables"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <PerformanceTimeCard
                        isLoading={isloadingDaily}
                        date={
                          daily?.netcashes[daily?.netcashes.length - 1]
                            .dtNetcash
                        }
                        date2={
                          daily?.netcashes[daily?.netcashes.length - 2]
                            .dtNetcash
                        }
                        date3={
                          daily?.netcashes[daily?.netcashes.length - 3]
                            .dtNetcash
                        }
                        value={
                          daily?.netcashes[daily?.netcashes.length - 1]
                            .amtCollections
                        }
                        value2={
                          daily?.netcashes[daily?.netcashes.length - 2]
                            .amtCollections
                        }
                        value3={
                          daily?.netcashes[daily?.netcashes.length - 3]
                            .amtCollections
                        }
                        title="Collections"
                      />
                    </Col>
                    <Col md={6}>
                      <PerformanceTimeCard
                        isLoading={isloadingDaily}
                        date={
                          daily?.netcashes[daily?.netcashes.length - 1]
                            .dtNetcash
                        }
                        date2={
                          daily?.netcashes[daily?.netcashes.length - 2]
                            .dtNetcash
                        }
                        date3={
                          daily?.netcashes[daily?.netcashes.length - 3]
                            .dtNetcash
                        }
                        value={
                          daily?.netcashes[daily?.netcashes.length - 1]
                            .amtNetcash
                        }
                        value2={
                          daily?.netcashes[daily?.netcashes.length - 2]
                            .amtNetcash
                        }
                        value3={
                          daily?.netcashes[daily?.netcashes.length - 3]
                            .amtNetcash
                        }
                        title="Cash"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <PerformanceInsightsCard
                    title={"Tenant Insights"}
                    data={valueData}
                    isLoading={isloadingInsights}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  background: "#3a3b48 0% 0% no-repeat padding-box",
                  margin: "2px",
                }}
              >
                <Col style={{ padding: "30px" }}>
                  <UserListingPerformance
                    data={usersData}
                    isLoading={isloading}
                  />
                </Col>
              </Row>
              <Row style={{ "margin-top": "15px" }}>
                <Col md={4}>
                  <ReportDownloadCard
                    title={"Reports"}
                    data={report}
                    isLoading={isloadingReport}
                    tenant={param}
                  />
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={4}>
                          <PerformanceCard
                            isLoading={isloadingMetric}
                            value={metrics?.mlRunsT}
                            title="Trainer Runs"
                          />
                        </Col>
                        <Col md={4}>
                          <PerformanceCard
                            isLoading={isloadingMetric}
                            value={metrics?.mlRunsP}
                            title="Predictor Runs"
                          />
                        </Col>
                        <Col md={4}>
                          <PerformanceCard
                            isLoading={isloadingMetric}
                            value={metrics?.comments}
                            title="Comments"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <PerformanceCard
                            isLoading={isloadingMetric}
                            value={metrics?.autoEmailAlerts}
                            title="Auto Reminders"
                          />
                        </Col>
                        <Col md={4}>
                          <PerformanceCard
                            isLoading={isloadingMetric}
                            value={metrics?.adhocEmailAlerts}
                            title="Adhoc emails"
                          />
                        </Col>
                        <Col md={4}>
                          <PerformanceCard
                            isLoading={isloadingMetric}
                            value={metrics?.payments}
                            title="Payments"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  background: "#3a3b48 0% 0% no-repeat padding-box",
                  margin: "2px",
                }}
              >
                <Col>
                  <EventTable />
                </Col>
              </Row>
            </Page.Body>
          </Page>
        </PrivateLayout>
      </DashboardWrapper>
    </>
  );
}
