import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { DeActivateIcon, EditIcon } from "assets/icons";
import Filter from "ui/FilterUser";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Table from "./table";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import moment from "moment";
import ReactTooltip from "react-tooltip";

const TopSection = styled.div`
  .download {
    text-decoration: underline;
    font-family: Montserrat Alternates;
    font-size: 12px;
    line-height: 15px;
    color: #909090;
    cursor: pointer;
    margin: "10px";
    padding: "10px";
  }
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;

const StyledFilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;
const StyledParagraph = styled.p`
  letter-spacing: 0px;
  color: rgb(144, 144, 144);
  font-family: "Montserrat Alternates-Bold";
  font-size: 24px;
  line-height: 22px;
`;

export default function UserListingPerformance(props) {
  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);
  const [filterData, setFilterData] = useState({
    username: "",
    statusArray: [],
    roleArray: [],
    businessArray: [],
    sortOn: "createdAt",
    sortOrder: "desc",
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "First name",
        accessor: "givenName",
        id: "givenName",
        maxWidth: 100,
        disableSortBy: true,
        Cell: (props) => <Capitalize>{props.row.values.givenName}</Capitalize>,
      },
      {
        Header: "Mobile",
        accessor: "phoneNumber",
        id: "phoneNumber",
        maxWidth: 100,
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "email",
        id: "email",
        maxWidth: 150,
        disableSortBy: true,
      },
      {
        Header: "User Permission",
        accessor: "role",
        id: "role",
        maxWidth: 80,
        disableSortBy: true,
        Cell: (props) => (
          <>
            <Capitalize>
              {props.row.values.role == "simplicollect"
                ? "SimpliCollect"
                : props.row.values.role == "simplipay"
                ? "SimpliPay"
                : props.row.values.role == "SimpliCollect&Simplipay"
                ? "SimpliCollect and SimpliPay"
                : props.row.values.role}
            </Capitalize>
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        id: "status",
        maxWidth: 80,
        disableSortBy: true,
        Cell: (props) =>
          props.row.values.status === "active" ? (
            <Capitalize style={{ color: "#00CE7F" }}>
              {props.row.values.status}
            </Capitalize>
          ) : (
            <Capitalize>In Active</Capitalize>
          ),
      },
      {
        Header: "MFA",
        accessor: "isMfaEnabled",
        id: "isMfaEnabled",
        maxWidth: 100,
        Cell: (props) => {
          return (
            <div>
              {props.row.original.isMfaEnabled === true ? (
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-for="mfa"
                  data-tip={`MFA is enabled, SMS will be sent out to ${props.row.original.phoneNumber}`}
                >
                  <path
                    d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                    stroke="#3dbee5"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-for="mfa"
                  data-tip={`MFA is disabled`}
                >
                  <path
                    d="M3 3L21 21M17 10V8C17 5.23858 14.7614 3 12 3C11.0283 3 10.1213 3.27719 9.35386 3.75681M7.08383 7.08338C7.02878 7.38053 7 7.6869 7 8V10.0288M19.5614 19.5618C19.273 20.0348 18.8583 20.4201 18.362 20.673C17.7202 21 16.8802 21 15.2 21H8.8C7.11984 21 6.27976 21 5.63803 20.673C5.07354 20.3854 4.6146 19.9265 4.32698 19.362C4 18.7202 4 17.8802 4 16.2V14.8C4 13.1198 4 12.2798 4.32698 11.638C4.6146 11.0735 5.07354 10.6146 5.63803 10.327C5.99429 10.1455 6.41168 10.0647 7 10.0288M19.9998 14.4023C19.9978 12.9831 19.9731 12.227 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C17.773 10.0269 17.0169 10.0022 15.5977 10.0002M10 10H8.8C8.05259 10 7.47142 10 7 10.0288"
                    stroke="#9a9999"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              <ReactTooltip id="mfa" place={"top"} className="tooltip" />
            </div>
          );
        },
      },
      {
        Header: "Last Activity",
        accessor: "lastActivity",
        id: "lastActivity",
        disableSortBy: true,
        maxWidth: 100,
        Cell: (props) => (
          <Capitalize>
            {moment(props.row.values?.lastActivity).format("YYYY-MM-DD HH:ss")}
          </Capitalize>
        ),
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        maxWidth: 100,
        Cell: (props) => (
          <>
            <DeActivateIcon
              style={{ width: "15px", marginRight: "5px" }}
              font-size="18px"
              onClick={() => {}}
            />
            Delete
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ErrorHandlingComponent errors={""} />
      <TopSection>
        <Row>
          <Col>
            <StyledParagraph>Tenant Users</StyledParagraph>
          </Col>
        </Row>
      </TopSection>

      <Row>
        <Col>
          <StyledTable>
            <Table
              isloading={props?.isLoading}
              data={props.data}
              columns={columns}
              totalCount={0}
              rowClick={() => {}}
              handlePageChange={() => {}}
              initialPage={0}
              onHeaderClick={() => {}}
            />
          </StyledTable>
        </Col>
      </Row>
    </>
  );
}
