import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import React from "react";
import NumberFormat from 'react-number-format';

// Register the Calibri font family
Font.register({
    family: 'Montserrat',
    fonts: [
        { src: '/fonts/Montserrat/MontserratAlternates-Regular.ttf', fontWeight: 'normal' },
        { src: '/fonts/Montserrat/MontserratAlternates-Bold.ttf', fontWeight: 'bold' },
        { src: '/fonts/Montserrat/MontserratAlternates-Italic.ttf', fontStyle: 'italic' },
    ],
});

// Define styles using JavaScript objects
const styles = StyleSheet.create({
    body: {
        fontFamily: 'Montserrat',
        padding: 25,
        backgroundColor: '#3A3B48', // Background color for the entire document
    },
    container: {
        marginTop: 70,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    image: {
        width: 235, // Adjusted width to make the logo bigger
        height: 30, // Proportionally adjusted height to maintain aspect ratio
        marginBottom: 30,
    },
    invoiceText: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#A0A0A2',
        paddingBottom: 30,
        textAlign: 'left',
    },
    receiptDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between', // Space between static and dynamic text
        alignItems: 'flex-start', // Align items at the top for consistent alignment
        marginBottom: 13, // Add some spacing between each section
    },
    dynamicInvoiceNumbers: {
        textAlign: 'right',
        fontSize: 12,
        flexWrap: 'wrap', // Allow dynamic values to wrap to the next line
        width: '50%', // Ensure dynamic values take up appropriate space
        color: '#27B2D8', // Dynamic value color
        display: 'flex',
        justifyContent: 'flex-end', // Align to the right end
    },
    invoicetextmessage: {
        fontSize: 12,  // Smaller text for the receipt
        textAlign: 'left',
        color: '#A0A0A2', // Static text color
        width: '50%', // Ensure static text takes up appropriate space
    },
    lineSeparator: {
        borderBottomColor: '#A0A0A2', // Color for the separator
        borderBottomWidth: 0.9,         // Line thickness
        marginVertical: 10,             // Spacing above and below the line
        marginBottom: 25,
    },
    message: {
        fontSize: 12,
        padding: 5,
        paddingLeft: 0,
        textAlign: 'left',
        color: '#A0A0A2', // Value color for messages
    },
    endmessage: {
        fontSize: 18,
        fontWeight: 'bold',
        padding: 5,
        color: '#A0A0A2', // Value color for titles
        textAlign: 'left',
    },
    paidmessage: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#A0A0A2', // Value color for titles
        marginBottom: 0,
        textAlign: 'left',
    },
});

const PaymentReceipt = ({ paymentData }) => {
    console.log('inside payment receipt', paymentData);

    const {
        amount,
        tenantFullName,
        paidOn,
        invoice,
        pm,
        transactionId,
        referenceNumber,
        surcharge,
        surchargeAmount,
        surcharge_rate,
        baseAmount,
        partialPaid,
        balanceDue

    } = paymentData;
    let sanitizedAmount = amount?.includes(",") ? amount.replace(/,/g, '') : amount;
    let sanitizedSurchargeAmount =surchargeAmount &&  surchargeAmount?.includes(",") ? surchargeAmount.replace(/,/g, '') : surchargeAmount;
    let sanitizedBaseAmount = baseAmount&& typeof(baseAmount)=== "string" && baseAmount?.includes(",") ? baseAmount.replace(/,/g, '') : baseAmount;

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Image style={styles.image}
                            src={{
                                uri: "https://allassets.s3.us-east-1.amazonaws.com/simplilogo.png",
                                method: "GET",
                                headers: { "Cache-Control": "no-cache" },
                                body: "",
                            }}
                        />
                    </View>

                    {/* Payment Details */}
                    <Text style={styles.invoiceText}>Payment Receipt</Text>

                    <Text style={[styles.paidmessage, { marginBottom: 10 }]}>
                        You paid <Text style={{ color: '#A0A0A2' }}>${amount}</Text>
                    </Text>
                    <Text style={[styles.message, { color: '#A0A0A2', marginBottom: 10 }]}>to {tenantFullName} on {paidOn}</Text>

                    {invoice ? (
                        // Display Invoice Number if invoice is defined
                        <View style={styles.receiptDetails}>
                            <Text style={styles.invoicetextmessage}>Invoice Number</Text>
                            <Text style={styles.dynamicInvoiceNumbers}>
                                {Array.isArray(invoice) ? invoice.join(', ') : invoice}
                            </Text>
                        </View>
                    ) : referenceNumber ? (

                        <View style={styles.receiptDetails}>
                            <Text style={styles.invoicetextmessage}>Reference Number</Text>
                            <Text style={styles.dynamicInvoiceNumbers}>
                                {Array.isArray(referenceNumber) ? referenceNumber.join(', ') : referenceNumber}
                            </Text>
                        </View>
                    ) : null}

                    {surcharge && pm === "Credit Card" ? 
                    <>
                           {partialPaid ?
                           <>
                    <View  style={styles.receiptDetails}>
                           
                           <Text style={styles.invoicetextmessage}>Balance Due</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                    
                            <NumberFormat
                             value={parseFloat(balanceDue).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                        
                            </View>
                            <View  style={styles.receiptDetails}>
                           
                           <Text style={styles.invoicetextmessage}>Amount Paid</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                    
                            <NumberFormat
                             value={parseFloat(sanitizedBaseAmount).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                        
                            </View>
                        </>
                           :
                           <>
                    <View  style={styles.receiptDetails}>
                           
                           <Text style={styles.invoicetextmessage}>Amount</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                    
                            <NumberFormat
                             value={parseFloat(sanitizedBaseAmount).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                        </View>
                        
                        </>}
                    <View  style={styles.receiptDetails}>
                            <Text style={styles.invoicetextmessage}> Processing Fees ({surcharge_rate}%)</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                            {/* ${surchargeAmount} */}
                            <NumberFormat
                             value={parseFloat(sanitizedSurchargeAmount).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                    </View>
                    <View style={styles.receiptDetails}>
                     
                        <Text style={styles.invoicetextmessage}> Total Paid</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                            {/* ${amount} */}
                            <NumberFormat
                             value={parseFloat(sanitizedAmount).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                    </View>
                    </>
                    :
                    partialPaid ?       <>
                    <View  style={styles.receiptDetails}>
                           
                           <Text style={styles.invoicetextmessage}>Balance Due</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                    
                            <NumberFormat
                             value={parseFloat(balanceDue).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                        
                            </View>
                            <View  style={styles.receiptDetails}>
                           
                           <Text style={styles.invoicetextmessage}>Amount Paid</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                    
                            <NumberFormat
                             value={parseFloat(sanitizedAmount).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                        
                            </View>
                        </>:
                    
                    <View style={styles.receiptDetails}>
                        <Text style={styles.invoicetextmessage}> Total</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                        <NumberFormat
                             value={parseFloat(sanitizedAmount).toFixed(2)}   thousandSeparator={true}
                             prefix={"$"} displayType="text"
                             isNumericString={true}
                             decimalScale={2}
                            />
                        </Text>
                    </View>
                    }

                    <View style={styles.lineSeparator} />
                    <View style={styles.receiptDetails}>
                        <Text style={styles.invoicetextmessage}> Payment Method</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                            {pm}
                        </Text>
                    </View>

                    <View style={[styles.receiptDetails, { marginBottom: 10 }]}>
                        <Text style={styles.invoicetextmessage}> Transaction ID</Text>
                        <Text style={styles.dynamicInvoiceNumbers}>
                            {transactionId}
                        </Text>
                    </View>

                    <Text style={[styles.message, { marginTop: 25, marginBottom: 10 }]}>Thank you</Text>
                    <Text style={styles.endmessage}>{tenantFullName}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default PaymentReceipt;