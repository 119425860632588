import React from "react";
import { useEffect } from "react";
import moment from "moment";

import { BasicButton } from "ui/Buttons";
import customersServices from "services/customers.services";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import { Route_URLs } from "routesURLs";
import { GeneratePaymentPageReceipt } from "mutations/updateCustomers";
import NumberFormat from "react-number-format";
import PaymentReceipt from "utils/pdftemplates/paymentReceipt";
import { pdf } from "@react-pdf/renderer";
const SuccessPaymentPage = () => {
  const transactionId = sessionStorage.getItem("transactionId");
  const email = sessionStorage.getItem("email");
  const name = sessionStorage.getItem("name");
  const amount = sessionStorage.getItem("amount");
  let timestamp = sessionStorage.getItem("timestamp");
  const status = sessionStorage.getItem("status");
  const transactionType = sessionStorage.getItem("transactionType");
  const customerId = sessionStorage.getItem("customerId");
  const tenant = sessionStorage.getItem('tenant')
  const [loading, setLoading] = useState(true);
  let type;
  timestamp = parseInt(timestamp);
  const history = useHistory();
  let dataSet = {
    transactionId: transactionId,
    tenant: tenant
  }
  const receptData =  GeneratePaymentPageReceipt(dataSet)
  useEffect(()=>{
   
  },[receptData])
  if (transactionType == "ach") {
    type = "ACH";
  } else if (transactionType == "Credit") {
    type = "Credit Card";
  } else if (transactionType == "Debit") {
    type = "Debit Card";
  } else if (transactionType == "classic_ach") {
    type = "Classic ACH";
  }
  const handleDownload = async () => {
    // const url = await customersServices.downloadPdf(transactionId, customerId);

    // console.log('transaction data ', res);
    const doc = <PaymentReceipt paymentData={receptData.data} />;

    // const link = document.createElement("a");

    // Generate a blob from the PDF document
    const asPdf = pdf(); // Initialize the pdf() generator
    asPdf.updateContainer(doc); // Load the document into the pdf container

    // Convert the document to a blob
    const blobdata = await asPdf.toBlob();
    // link.href = url;
    const url = URL.createObjectURL(blobdata);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Transaction_${receptData.data.transactionId}.pdf`; // Specify the filename for the downloaded file
    link.click();
    // Clean up the URL object
    URL.revokeObjectURL(url);
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace(Route_URLs.signin);
      }
    };
  }, [history]);
  let sanitizedAmount = receptData?.data?.baseAmount ? receptData?.data?.baseAmount : receptData?.data?.amount
  console.log("typeof", typeof sanitizedAmount)
  sanitizedAmount = /^(\d{1,3})(,\d{3})*(\.\d+)?$/?.test(sanitizedAmount) && typeof sanitizedAmount !== "number" ? sanitizedAmount?.replace(/,/g, "") : sanitizedAmount
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {receptData?.isLoading ? (
        <>
          <Spinner animation="border" style={{ color: "#fff" }}></Spinner>
        </>
      ) : (
        <div>
          <div
            style={{
              border: "1px solid grey",
              background: "rgb(58,59,73)",
              padding: "20px 40px",
              borderRadius: "8px",
            }}
          >
           <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {status === "APPROVED" ? (
                <h2
                  style={{
                    color: "#32A200",
                    fontWeight: "600",
                    fontSize: "34px",
                    padding: "0px 25px",
                  }}
                >
                  Success!
                </h2>
              ) : (

                <h2
                  style={{
                    color: "#d5d51e",
                    fontWeight: "600",
                    fontSize: "34px",
                    padding: "0px 25px",
                  }}
                >
                     Processing!

                </h2>


              )}
            </div>

            <div
              style={{
                color: "grey",
                borderTop: "1px solid lightgray",
                paddingTop: "10px",
                display: "grid"
              }}
            >
               <table>
               {/* <tr>
             <td style={{ fontWeight: "600", textAlign: "left" }}>
             Invoice Number
               </td>
               <td
                 style={{
                   textAlign: "end",
                   color: "#909090",
                   fontWeight: "600",
                 }}
               >
                    
               </td>
             </tr> */}
            {receptData?.data?.invoice ? <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Invoice Number
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                     {Array.isArray(receptData?.data?.invoice) ? receptData?.data?.invoice.join(', ') : receptData?.data?.invoice}
                  </td>
                </tr> : receptData?.data?.referenceNumber ?<tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Reference Number
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    {receptData?.data?.referenceNumber}
                  </td>
                </tr> : ""}
                <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Payment Method
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    {receptData?.data?.pm}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    TransactionId
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    {receptData?.data?.transactionId}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                  Transaction Date
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    {receptData?.data?.paidOn}
                  </td>
                </tr>
                {receptData?.data?.partialPaid ? <tr >
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Balance Due
                  </td>
                  <td  style={{
                   textAlign: "end",
                   color: "#909090",
                   fontWeight: "600",
                 }}>
                    <NumberFormat
               value={parseFloat(receptData?.data?.balanceDue).toFixed(2)}   thousandSeparator={true}
               prefix={"$"} displayType="text"
               isNumericString={true}
               decimalScale={2}
                />
                
                  </td>
                </tr>:""}
             
               { receptData?.data?.surcharge === true || receptData?.data?.partialPaid ?
               <>
                <tr>
               {receptData?.data?.partialPaid ? <td style={{ fontWeight: "600", textAlign: "left" }}>
                 Amount Paid
               </td>:
                 <td style={{ fontWeight: "600", textAlign: "left" }}>
                 Amount
               </td>}
               <td
                 style={{
                   textAlign: "end",
                   color: "#909090",
                   fontWeight: "600",
                 }}
               >
                <NumberFormat
               value={parseFloat(sanitizedAmount).toFixed(2)}   thousandSeparator={true}
               prefix={"$"} displayType="text"
               isNumericString={true}
               decimalScale={2}
                />
                 
               </td>  
             
             </tr>

             {receptData?.data?.surchargeAmount &&<tr>
             <td style={{ fontWeight: "600", textAlign: "left" }}>
             Processing Fees ({receptData?.data?.surcharge_rate}%) 
               </td>
               <td
                 style={{
                   textAlign: "end",
                   color: "#909090",
                   fontWeight: "600",
                 }}
               >
                    <NumberFormat
               value={receptData?.data?.surchargeAmount}   thousandSeparator={true}
               prefix={"$"} displayType="text"
               isNumericString={true}
               decimalScale={2}
                />
               </td>
             </tr>}
             <tr>
          
             <td style={{ fontWeight: "600", textAlign: "left" }}>
                 Total 
               </td>
               <td
                 style={{
                   textAlign: "end",
                   color: "#909090",
                   fontWeight: "600",
                 }}
               >
                    <NumberFormat
               value={receptData?.data?.amount}   thousandSeparator={true}
               prefix={"$"} displayType="text"
               isNumericString={true}
               decimalScale={2}
                />
               </td>
             </tr>
             
               </>
               
               :
               <tr>
                  <td style={{ fontWeight: "600", textAlign: "left" }}>
                    Total 
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      color: "#909090",
                      fontWeight: "600",
                    }}
                  >
                    ${receptData?.data?.amount}
                  </td>
                </tr>}
                <tr></tr>
           
              </table>
              <BasicButton
                onClick={handleDownload}
                style={{
                  background:
                    "linear-gradient(270deg, rgb(51, 194, 223) 0%, rgb(0, 129, 197) 100%) 0% 0% no-repeat;",
                  width: "98%",
                }}
              >
                Generate Receipt
              </BasicButton>
              {
                transactionType === "ach" ? (
                  <div
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "13px",
                      padding: "12px 35px 5px 35px",
                    }}
                  >
                    *Transactions done through Plaid will be processed within 2 business days.
                  </div>
                ) : transactionType === "classic_ach" ? (
                  <div
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "13px",
                      padding: "12px 35px 5px 35px",
                    }}
                  >

                    *Transactions will be processed within 7 business days.
                  </div>
                ) : ""
              }


              <p style={{ textAlign: "center", marginTop: "15px" }}>
                &copy;2024 SimpliCapital.All rights Reserved{" "}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuccessPaymentPage;
