import { Route_URLs } from "routesURLs";
import CollapsedLogo from "assets/logo.png";
import styled from "styled-components";
import achBankTransfer from "../../../assets/images/ach_img.png";
import credit from "../../../assets/images/creditCrad.png";
import debit from "../../../assets/images/debitCard.png";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import jwtDecode from "jwt-decode";
import {
    Button, Col, Form, Row, Spinner, ModalFooter,
} from "react-bootstrap";
import state from "assets/state.json"
import { useHistory } from "react-router-dom";
import { GetCustomerInvoiceData } from "queries/customers";
import Modal from "react-bootstrap/Modal";
import { BasicButton } from "ui/Buttons";
import Logo from "assets/SimpliCapital-logo.png";
import { StyledModal } from "ui/Modal";
import { GenerateCheckSum, GenerateSessionToken, GetCustomerInvoice, SaveTransactionPayment } from "mutations/updateCustomers";
import {verify} from "jsonwebtoken"
import NumberFormat from "react-number-format";
import { EditIcon } from "assets/icons";
// import { jwtVerify } from "jose";



const StyledCollapsedLogo = styled.div`
  img {
    width: auto;
  }
`;
const BackStyledButton = styled.button`
  background: transparent linear-gradient(270deg, #33c2df 0%, #0081c5 100%);
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  text-align: left;
  font-family: Montserrat Alternates-semiBold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
`;

export default function PaymentPage() {
    const [payment, setPayment] = useState("");
    const [currency, setCurrency] = useState("USD");
    const [userTokenId, setUserTokenId] = useState("");
    const [customer, setCustomer] = useState(null);
    const [edit, setEdit] = useState(false);
    const [isPaid, setIsPaid] = useState(false)
    const [closeWindow, setCloseWindow] = useState(false)
    const [classicAch, setClassicAch] = useState(false)
    const [showModal, setShowModal] = useState(true);
    const [success, setSuccess] = useState(false)
    const [paymentPermission, setPaymentPermission] = useState("")
    const [customerEmail,setCustomerEmail] = useState("")

    const [editingRow, setEditingRow] = useState(null); 
    const [editedAmount, setEditedAmount] = useState("");
    const [loaderForPaymentChange,setLoaderForPaymentChange] = useState(false)
    const [changes,setChanges]=useState()


    const getInvoiceDetails= GetCustomerInvoice()
    const saveTransaction= SaveTransactionPayment()

    const generateSessionToken = GenerateSessionToken()
    const generateCheckSum = GenerateCheckSum()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let history = useHistory();
    let token = searchParams.get("token")
    let invoiceId = searchParams.get("invoiceId")
    let dataSet = {
        token,
        invoiceId
    }




    useEffect(() => {
        const script = document.createElement('script');
        script.src = process.env.REACT_APP_NUVEI_CHECKOUT_JS; // Replace with your script URL
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Cleanup on unmount
        };
    }, []);

   

    
    useEffect(async () => {

      

        let customerAndInvoiceData = await getInvoiceDetails.mutateAsync(dataSet)

        if (customerAndInvoiceData?.response) {
            if (!customerAndInvoiceData?.response?.isPaid) {
     
                let decrypt = verify(customerAndInvoiceData?.response,process.env.REACT_APP_DPI_TOKEN)       
                decrypt["originalAmount"] = decrypt.amount
                setCustomerEmail(decrypt.email)
                setCustomer(decrypt)
                setPaymentPermission(decrypt.paymentOption)
            } else {
                setIsPaid(customerAndInvoiceData?.response?.isPaid)
            }

        }



    }, [])
    

    let amount
    let newAmount
    let surchargeRate = 0
    if (customer?.amount) {
        amount = customer?.amount
        if(customer.surcharge){
            surchargeRate = customer.surcharge_rate
        }
        var Surcharge = amount * (surchargeRate / 100);
        Surcharge = Surcharge.toFixed(2);

        var updatedAmount = parseFloat(amount) + parseFloat(Surcharge);
        // var updatedAmount =parseFloat(amount)

        newAmount = amount

    }

    useEffect(() => {
        if (amount > 5000 && paymentPermission?.includes('classic_ach')) {
            setClassicAch(true)
            setPayment("classic_ach")
          } else {
            if(paymentPermission?.includes('ach')){
              setClassicAch(false)
              setPayment("ach")
            }
        
          }

    }, [amount,customer])
  




    const handleClose = () => setShowModal(false);

    const handleClassicAch = () => {

        setPayment("classic_ach")
        setShowModal(false)
        setClassicAch(true)
    }

    const handleEdit = () => {
        const hasErrors = Object.values(errors).some(error => error !== '');

        if (edit) {
            if (hasErrors) {
                setEdit(true);

            } else {
                setEdit(false);
            }
        } else
            setEdit(true)
    }

    const [formData, setFormData] = useState({
        givenName: "",
        familyName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
        state: "",
    });

    const [billingAddr, setBillingAddr] = useState({
        givenName: "",
        familyName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
        state: "",
    });

    let isMissingData

    useEffect(() => {
        if (customer) {
     
            setBillingAddr({
                givenName: customer.givenName || "",
                familyName: customer.familyName || "",
                email: customer.email || "",
                phone: customer.phoneNumber || "",
                address: customer.address || "",
                city: customer.city || "",
                state: customer.state || "",
                zip: customer.zip || "",
            });

            setFormData({
                givenName: customer.givenName || "",
                familyName: customer.familyName || "",
                email: customer.email || "",
                phone: customer.phoneNumber || "",
                address: customer.address || "",
                city: customer.city || "",
                state: customer.state || "",
                zip: customer.zip || "",
            });
          
            const initialErrors = {
                email: validateEmail(customer.email || "").message,
                givenName: validateGivenName(customer.givenName || "").message,
                familyName: validateFamilyName(customer.familyName || "").message,
                address: validateAddress(customer.address || "").message,
                city: validateCity(customer.city || "").message,
                state: validateState(customer.state || "").message,
                zip: validateZip(customer.zip || "").message,
                phone: validatePhone(customer.phoneNumber || "").message
            };

            // setErrors(initialErrors);
            
        
            isMissingData = Object.values(initialErrors).some(error => error !== "");
       


            if (isMissingData) {
                setEdit(true);
            }
        }



    }, [customer]);







    const [validated, setValidated] = useState(false);

    const [zipError, setZipError] = useState('');
 
    const [errors, setErrors] = useState({
        email: '',
        givenName: '',
        familyName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: ''
    });





    const handleChange = (e) => {
        const { id, value } = e.target;



        setBillingAddr({
            ...billingAddr,
            [id]: value
        });



        // Perform validation for the field
        switch (id) {
            case 'email':
                setErrors({
                    ...errors,
                    email: validateEmail(value).message
                });
                break;
            case 'givenName':
                setErrors({
                    ...errors,
                    givenName: validateGivenName(value).message
                });
                break;
            case 'familyName':
                setErrors({
                    ...errors,
                    familyName: validateFamilyName(value).message
                });
                break;
            case 'address':
                setErrors({
                    ...errors,
                    address: validateAddress(value).message
                });
                break;
            case 'city':
                setErrors({
                    ...errors,
                    city: validateCity(value).message
                });
                break;
            case 'state':
                setErrors({
                    ...errors,
                    state: validateState(value).message
                });
                break;
            case 'zip':
                setErrors({
                    ...errors,
                    zip: validateZip(value).message
                });
                break;
            case 'phone':
                setErrors({
                    ...errors,
                    phone: validatePhone(value).message
                });
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields before submission
        const {
            email,
            givenName,
            familyName,
            address,
            city,
            state,
            zip,
            phone
        } = billingAddr;

        const emailValidationResult = validateEmail(email);
        const givenNameValidationResult = validateGivenName(givenName);
        const familyNameValidationResult = validateFamilyName(familyName);
        const addressValidationResult = validateAddress(address);
        const cityValidationResult = validateCity(city);
        const stateValidationResult = validateState(state);
        const zipValidationResult = validateZip(zip);
        const phoneValidationResult = validatePhone(phone);

        setErrors({
            email: emailValidationResult.message,
            givenName: givenNameValidationResult.message,
            familyName: familyNameValidationResult.message,
            address: addressValidationResult.message,
            city: cityValidationResult.message,
            state: stateValidationResult.message,
            zip: zipValidationResult.message,
            phone: phoneValidationResult.message
        });

        // Check overall form validity
        const isValid =
            emailValidationResult.valid &&
            givenNameValidationResult.valid &&
            familyNameValidationResult.valid &&
            addressValidationResult.valid &&
            cityValidationResult.valid &&
            stateValidationResult.valid &&
            zipValidationResult.valid &&
            phoneValidationResult.valid;

        setValidated(isValid);



        if (isValid) {
            setFormData({
                givenName: givenName,
                familyName: familyName,
                address: address,
                city: city,
                email: email,
                state: state,
                zip: zip,
                phone: phone

            })
            // handleNuvei()
            let dataSet = {...customer,givenName: givenName,

                familyName: familyName,

                address: address,

                city: city,

                email: email,

                state: state,

                zip: zip,

                phoneNumber: phone}

            setCustomer(dataSet)

            setEdit(false)

        } else {
            setEdit(true)


        }
    };


    // Validation functions (example implementations)
    const validateEmail = (email) => {

        if (!email) {
            return {
                valid: false,
                message: 'Email is required'
            };
        }

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return {
            valid: regex.test(email),
            message: regex.test(email) ? '' : 'Invalid email address'
        };
    };

    const validateGivenName = (name) => {
        return {
            valid: name.trim().length > 0,
            message: name.trim().length > 0 ? '' : 'Given name is required'
        };
    };

    const validateFamilyName = (name) => {
        return {
            valid: name.trim().length > 0,
            message: name.trim().length > 0 ? '' : 'Family name is required'
        };
    };

    const validateAddress = (address) => {
        const trimmedAddress = address.trim();
      
      if (trimmedAddress.length === 0) {
        return {
          valid: false,
          message: "Address is required",
        };
      }
       else if (trimmedAddress.length > 50) {
        return {
          valid: false,
          message: `Address must be less than ${50} characters`,
        };
      }
    
      return {
        valid: true,
        message: "",
      };
      };
    

    const validateCity = (city) => {
        return {
            valid: city.trim().length > 0,
            message: city.trim().length > 0 ? '' : 'City is required'
        };
    };

    const validateState = (state) => {
        return {
            valid: state.trim().length > 0,
            message: state.trim().length > 0 ? '' : 'State is required'
        };
    };

    const validateZip = (zip) => {
        if (!zip) {
            return {
                valid: false,
                message: 'Zip code is required'
            };
        }

        const regex = /^\d*-?\d*$/;
        return {
            valid: regex.test(zip),
            message: regex.test(zip) ? '' : 'Invalid ZIP code'
        };
    };

    const validatePhone = (phone) => {

        if (!phone) {
            return {
                valid: false,
                message: 'Phone is required'
            };
        }
        const regex = /^[0-9]{10}$/;
        return {
            valid: regex.test(phone),
            message: regex.test(phone) ? '' : 'Invalid phone number'
        };
    };


        useEffect(() => {

        if (paymentPermission?.includes('classic_ach') && !paymentPermission?.includes('ach')) {
          setPayment("classic_ach");
          setClassicAch(true)
        } else if (paymentPermission?.includes('ach')) {
          setPayment("ach")
        }
        // else if(!paymentPermission?.includes('ach') && !paymentPermission?.includes('classic_ach')){
        //     setPayment("credit_card")
        // }else if(!paymentPermission?.includes('ach') && !paymentPermission?.includes('classic_ach') && !paymentPermission?.includes("card")){
        //     setPayment("card")
        // }
      }, [paymentPermission])






    // --------------------nuvei implementation-----------
    function getCurrentDateTime() {
        let now = new Date();
        let year = now.getFullYear();
        let month = padZero(now.getMonth() + 1);
        let day = padZero(now.getDate());
        let hours = padZero(now.getHours());
        let minutes = padZero(now.getMinutes());
        let seconds = padZero(now.getSeconds());

        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }

    function padZero(number) {
        return number < 10 ? "0" + number : number;
    }
    let partialAmountTotal = customer?.amount
    useEffect(()=>{
            setChanges(`${payment}-${formData}-${amount}-${edit}`)
    },[payment,formData,amount,edit])
    useEffect(() => {
    
        setTimeout(() => {
      
            handleNuvei()
        }, 100);
    }, [changes])
    // useEffect(() => {

    //     // handleNuvei()
    //     setTimeout(() => {
    //         if(payment)
    //         handleNuvei()
    //     }, 500);
    // }, [payment, formData])

    const handleEditClick = (index, currentAmount) => {
        setEditingRow(index); // Set the current row as editable
        setEditedAmount(currentAmount); // Set the initial value of the amount in input
      };
    const [errorMessage,setErrorMessage] = useState("")


    const handleSaveClick = (index) => {
    
        // Validation: Check if the editedAmount is valid
        if (isNaN(editedAmount) || parseFloat(editedAmount) <= 0) {
          setErrorMessage("Please enter a valid amount greater than 0");
          return; // Stop execution if validation fails
        }
          // console.log("index---->",invoiceDetails[index].orginalAmount ,editedAmount)
          if (parseFloat(editedAmount) > customer.orginalAmount) {
            setErrorMessage("Amount should be equal to or less than the balance due.");
            return; // Stop execution if validation fails
          }
      
        // Clear error message if the validation passes
        setErrorMessage("");
      
        // Update the amount for the specific invoice
        const updatedInvoiceDetails = editedAmount !== ""
            ? { ...customer, amount: editedAmount }
            : customer
      
      
        // Update the invoice details state
        setCustomer(updatedInvoiceDetails);
      
        // Clear the editing state
        setEditingRow(null);
      };
     

    const handleNuvei = async (event) => {
        if (customer != null) {
            event?.preventDefault();
            const clientRequestId = Date.now().toString();
            const timeStamp = getCurrentDateTime();

            try {
        
                if (!edit) {

                    setLoaderForPaymentChange(true)
                  

                    const checksumResponse = await generateCheckSum.mutateAsync({
                         clientRequestId,
                         amount:
                         payment === "credit_card"
                         ?  (parseFloat(amount) + parseFloat(Surcharge)).toFixed(2)
                         :  amount,
                            currency,
                            timeStamp,
                            email: customer.email,
                            tenant: customer.tenant
                      })
                
                      const { checksum } = checksumResponse;
                   



                    const sessionTokenResponse = await generateSessionToken.mutateAsync({
                        clientRequestId,
                        amount:
                        payment === "credit_card"
      ?  (parseFloat(amount) + parseFloat(Surcharge)).toFixed(2)
      :  amount,
                        currency,
                        timeStamp,
                        checksum,
                        email: customer.email,
                        tenant: customer.tenant
                    })


                    const { sessionToken } = sessionTokenResponse;

                    let cardType;
                    var billingDetails;
                    if (payment === "credit_card") {
                        cardType = "Credit";
                    } else if (payment === "card") {
                        cardType = "Debit";
                    } else if (payment === "ach") {
                        cardType = "ach";
                    } else if (payment === "classic_ach") {
                        cardType = "classic_ach";
                    }
                    if (formData) {
                        if (payment === "ach") {
                            billingDetails = {
                                firstName: formData?.givenName,
                                lastName: formData?.familyName,

                                email: formData.email,
                                phone: formData.phone,
                                 address: formData.address?.slice(0,50),
                                city: formData.city,
                                zip: formData.zip,
                                state: formData.state,
                                country: "US",
                            };
                        } else {

                            billingDetails = {
                                firstName: formData.givenName,
                                lastName: formData.familyName,
                                email: formData.email,
                                phone: formData.phone,
                                 address: formData.address?.slice(0,50),
                                city: formData.city,
                                zip: formData.zip,
                                state: formData.state,
                                country: "US",
                            };
                        }
                    }

                    async function getInvoiceStatus(result) {
                

                        
                        let customerAndInvoiceData = await getInvoiceDetails.mutateAsync(dataSet)
                        if (customerAndInvoiceData?.response) {
                            return new Promise((resolve, reject) => {
                                if (customerAndInvoiceData?.response?.isPaid) {

                                    reject("Invoice is already paid")
                                } else {


                                    resolve()
                                }
                            })


                        }
                    }

                    async function transactionStatus(result) {
                        let partialPay = false
                        let dataSet = {
                            InvoiceArray: customer.invoiceId,
                            Type: cardType,
                            TransactionTimeStamp: moment().valueOf(),
                            TotalAmount: payment === "credit_card"
                                ? parseFloat(amount) + parseFloat(Surcharge)
                                : amount,
                            transactionDetails: [result],
                            transactionId: result?.transactionId,
                            email: customerEmail,
                            billingAddress: billingDetails,
                            token:token,
                            invoiceAmount: amount,

                        };
                        if(customer.originalAmount !==  customer.amount){
                            
                            partialPay = true
                            dataSet["partialPay"]= true
                          }
                          if(partialPay == true){
                            let dateSet = {
                                invoiceNumber: customer.invoiceId,
                                originalAmount: customer.originalAmount,
                                paidAmount: parseFloat(customer.amount)
                              }
                              if(customer.orginalAmount !== customer.amount){
                                dateSet["partialPaid"]= true
                              }
                              dataSet["partialPaymentDetails"] = [dateSet]
                          }
                        if(customer.surcharge && payment === "credit_card"){
                            dataSet["surcharge"] = Surcharge
                            dataSet["surcharge_rate"] = customer.surcharge_rate
                          }
                     
        

                       await saveTransaction.mutateAsync(dataSet)
                        
                        return new Promise(async (resolve, reject) => {
                        
                            
                            if (result.result === "APPROVED" || result.result === "PENDING") {

                                sessionStorage.setItem("transactionId", result?.transactionId)
                                sessionStorage.setItem("email", billingDetails.email)
                                sessionStorage.setItem("name", billingDetails.firstName + " " + billingDetails.lastName)
                                sessionStorage.setItem("amount", dataSet.TotalAmount)

                                sessionStorage.setItem('status', result.result)
                                sessionStorage.setItem('timestamp', moment().valueOf())
                                sessionStorage.setItem('transactionType', cardType)
                                sessionStorage.setItem('customerId', customer.customerId)
                                history.push(Route_URLs.paymentProcessing);
                                sessionStorage.setItem('tenant',customer.tenant)
                            }

                            resolve();
                        });


                    }
                    setLoaderForPaymentChange(false)

                    if (billingDetails) {
                        await window.checkout({
                            env: "prod",
                            merchantId: customer.merchantId,
                            merchantSiteId: customer.merchantSiteId,
                            clientRequestId,
                            timeStamp,
                            checksum,
                            currency,
                            amount: 
                                payment === "credit_card"
                                    ? parseFloat(amount) + parseFloat(Surcharge)
                                    : amount,

                            sessionToken,
                            userTokenId,
                            cssUrl: "styles.css",
                            showResponseMessage: true,

                            renderTo: "#nuveiCheckout",
                            country: "US",
                            transactionType: "SALE",
                            pmWhitelist: [
                                payment === "card" || payment === "credit_card"
                                    ? "cc_card"
                                    : payment === "ach"
                                        ? "apmgw_Secure_Bank_Transfer"
                                        : payment === "classic_ach" ? "apmgw_ACH" : ""
                            ],
                            savePM: false,

                            billingAddress: billingDetails,
                            urlDetails: {
                                successUrl:
                                    `${process.env.REACT_APP_SIMPLI_WEB_URL}customer/successPage`,
                                pendingUrl:
                                    `${process.env.REACT_APP_SIMPLI_WEB_URL}customer/successPage`,
                            },
                            apmWindowType: "popup",
                            prePayment: async (paymentDetails) => {

                                await getInvoiceStatus()
                                return new Promise(async (resolve, reject) => {
                                    if (paymentDetails?.paymentOption?.card) {
                                        if (paymentDetails.paymentOption.card.cardType === cardType) {
                                            resolve();
                                        } else {
                                            reject(`Please enter ${cardType} card details only.`);
                                        }
                                    } else {
                                        resolve();
                                    }
                                });
                            },
                            onResult: async (res) => {

                                if (res.result === "APPROVED" || res.result === "PENDING") {
                                    setSuccess(true)

                                }
                                if (res.transactionStatus == "DECLINED") {
                                    setTimeout(() => {
                                        let diclinedMsg = "system error";
                                        if (res.errorDescription) {
                                            diclinedMsg = res.errorDescription;
                                        }
                                        if (res.gwErrorReason) {
                                            diclinedMsg = res.gwErrorReason;
                                        }

                                        if (document.getElementsByClassName("sfc-dialog-message")) {
                              

                                            document.querySelector(".sfc-dialog-message").innerText =
                                                document.querySelector(".sfc-dialog-message").innerText +
                                                " Because of " +
                                                diclinedMsg;
                                        }
                                    }, 100);
                                }
                                if(res.error!='no payment options to display'){

                                    await transactionStatus(res);
                                }
                            },

                            onPaymentEvent: async (paymentDetails) => {

                                if (paymentDetails.eventType === "redirectionEnded"&& paymentPermission.includes("classic_ach")) {
                                    setTimeout(() => {
                                        setCloseWindow(true)
                                    }, 1000)



                                }
                            },
                            preventACHNewWindow: true,


                        });
                    }
                }

            } catch (error) {

            }
        }
    };

    return (

        <>
            {closeWindow && !success ?
                <Modal className="ach_modal"
                    show={showModal}
                    onRequestClose={handleClose}
                    style={{ display: "flex" }}

                >
                    <button className="ach__modal_button" onClick={handleClose}>x</button>

                    <div style={{ marginBottom: "20px", fontWeight: "600" }}>
                        Didn't find your bank?<br />
                        Let's enter your bank details manually.
                    </div>
                    <ModalFooter>
                        <BasicButton style={{ height: "38px" }} onClick={handleClassicAch}>
                            OK
                        </BasicButton>
                        <Button className="close_button" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </ModalFooter>

                </Modal> : ""}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "auto", position: "absolute", top: "50%", left: "50%", }} className="payment-body">
                {customer !== null && paymentPermission.length > 0
                    // && expire == false
                    ? <div
                        style={{
                            backgroundColor: "rgb(59, 58, 73)",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            padding: "20px",
                            borderRadius: "10px",
                            width: "1000px",
                        }}
                    >
                        <div
                            style={{
                                width: "62%",
                                backgroundColor: "rgb(59,58,73)",
                                borderRadius: "7px",

                                borderRight: "1px solid #2C2C31",
                            }}
                        >
                                     <div className="scrollable-table" style={{padding: "5px",textAlign:"center",borderBottom:"solid 2px #808080"}}>
              
              <h2
                  style={{
                    color: "rgb(195,195,195)",
                    // fontFamily: "sans-serif",
                    fontWeight: "bold",
                    marginBottom: "auto",
                    paddingBottom:"2%"
                  }}
                >
                  Selected Invoices
                </h2>
                <div style={{maxHeight: "210px", overflowY: "auto",}} className="scrollbar-payment">
                <table style={{ width: "100%", color: "#fff" }}>
      <thead style={{position:"sticky",top:"0"}}>
        <tr style={{ background: "linear-gradient(270deg, rgb(51, 194, 223) 0%, rgb(0, 129, 197) 100%) 0% 0% no-repeat", height: "42px", fontSize: "11px", lineHeight: "25px", fontFamily: "Montserrat Alternates-semiBold" }}>
        <th>#</th>
          <th>Invoice Number</th>
          <th> Balance Due</th>
          <th>Amount to Pay</th>

        </tr>
      </thead>
      <tbody>
      
          <tr  style={{ color: "#b5b5b5", borderBottom: "solid 1px #808080", fontSize: "11px", lineHeight: "25px", fontFamily: "Montserrat Alternates" }}>
                <td>1</td>

            <td>{customer.invoiceId}</td>
            <td>
            <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={parseFloat(customer.originalAmount).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            /> 
            </td>
            <td style={{
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",textAlign: "center",alignItems: "center",justifyContent: "center"
}}>
              {editingRow === 1 ? (
                <>
                <span style={{
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
}}> 
                <input
                // style={{ -webkit-appearance: none;}}
                    type="number"
                    value={editedAmount}
                    onChange={(e) => setEditedAmount(e.target.value)}
                    style={{ width: "80px" }}
                    // onBlur={() => handleSaveClick(index)}
                    // onChange={(e) => handleSaveClick(index)}
                    autoFocus
                    disabled ={loaderForPaymentChange}
                  />
                  <BasicButton  style= {{
    background: "transparent",
    border: "none",
    color: "green",
    fontSize: "20px",
  position:"static"
  
}}onClick={() => handleSaveClick(1)}>✓</BasicButton>
                  <div style= {{
    background: "transparent",
    border: "none",
    color: "red",
    fontSize: "10px",
    cursor:"pointer",
      // paddingLeft:"15px",
  position:"static"

}} onClick={() => {setEditingRow(null);  setEditedAmount('')}}>❌</div>

                </span>
               
                   {errorMessage && <p style={{ color: "red" ,fontSize:"9px",lineHeight:"11px",margin:"0px"}}>{errorMessage}</p>}
                </>
              ) : (
                <sapn style={{display:"flex",justifyContent:"center",gap:"5px",alignItems:"center"}}>
                     <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={parseFloat(customer?.amount).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            /> 

                  <span style={{display: loaderForPaymentChange ? "none":""}}>
                  <EditIcon
                    className="forecast-data-edit noBarClick"
                    style={{ marginLeft: "auto", width: "1rem" }}
                    onClick={() => handleEditClick(1, customer.amount)
                    
                    }
                  />
                  </span>
                </sapn>
              )}
            </td>
          </tr>
    
      </tbody>
    </table>

                </div>

    <div style={{ display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: "20%",
    paddingRight: "12%",
    color: "rgb(181, 181, 181)",
    paddingTop: "1%",paddingBottom:"2%",fontSize: "11px", lineHeight: "25px", fontFamily: "Montserrat Alternates-semiBold" }}><span>Total</span><span>       <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={parseFloat(partialAmountTotal).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            /> </span></div>
            {/* <hr style={{height:"5px"}}/> */}
            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: "10px",
                                }}
                            >
                                <div style={{ padding: "10px 5px" }}>
                                    <StyledCollapsedLogo className="collapsed-logo">
                                        <a href={Route_URLs.dashboard}>
                                            <img alt="SimpliCapital" src={CollapsedLogo}></img>

                                        </a>
                                    </StyledCollapsedLogo>
                                </div>
                                <div>
                                    <h2
                                        style={{
                                            color: "rgb(195,195,195)",
                                            // fontFamily: "sans-serif",
                                            fontWeight: "bold",
                                            padding: "0px 7px ",
                                        }}
                                    >
                                        Secure Payment
                                    </h2>
                                    <h3
                                        style={{
                                            color: "rgb(195,195,195)",
                                            // fontFamily: "sans-serif",
                                            fontWeight: "bold",
                                            fontSize: "13px",
                                            padding: "0px 9px",
                                        }}
                                    >
                                        Choose Your Preferred Payment method
                                    </h3>
                                </div>
                            </div>
                            <div style={{ display: "block" }}>
              <div

                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "18px 29px",
                }}
              >


                {(paymentPermission?.includes('ach') || paymentPermission?.includes('classic_ach') ) && (
                  <div style={{ margin: "0px 10px" }}>
                    <span
                      onClick={() => {
                        classicAch ? setPayment("classic_ach") :
                          setPayment("ach");
                      }}
                      className={
                        payment === "ach" || payment === "classic_ach" ? "active_Bankcards" : "Bankcards"
                      }
                    >
                      <img
                        src={achBankTransfer}
                        alt="achBankTransfer"
                        style={{ height: "46px" }}
                      />
                    </span>
                    {classicAch ? <p
                      style={{
                        textAlign: "center",
                        color: "rgb(195, 195, 195)",
                        marginTop: "8px",
                      }}
                    >
                      ACH
                    </p>
                      : <p
                        style={{
                          textAlign: "center",
                          color: "rgb(195, 195, 195)",
                          marginTop: "8px",
                        }}
                      >
                        Bank Transfer

                      </p>}

                  </div>
                )}
                {paymentPermission?.includes('credit_card') ? (
                  <div style={{ margin: "0px 10px" }}>
                    <span
                      onClick={() => {
                        setPayment("credit_card");
                      }}
                      className={
                        payment === "credit_card"
                          ? "active_Bankcards"
                          : "Bankcards"
                      }
                    >
                      <img src={credit} alt="creditCard" />
                    </span>
                    <p
                      style={{
                        textAlign: "center",
                        color: "rgb(195, 195, 195)",
                        marginTop: "12px",
                      }}
                    >
                      Credit
                    </p>
                  </div>
                ) : ""}
                {paymentPermission?.includes('debit_card') ? (
                  <div style={{ margin: "0px 10px" }}>
                    <span
                      onClick={() => {
                        setPayment("card");
                      }}
                      className={
                        payment === "card" ? "active_Bankcards" : "Bankcards"
                      }
                    >
                      <img src={debit} alt="debitCard" />
                    </span>
                    <p
                      style={{
                        textAlign: "center",
                        color: "rgb(195, 195, 195)",
                        marginTop: "8px",
                      }}
                    >
                      Debit
                    </p>
                  </div>
                ) : ""}



              </div>

              { loaderForPaymentChange && <div className="payment-Loader">
                <Spinner animation="border" variant="light" />
                </div>}
              <div id="nuveiCheckout" className={loaderForPaymentChange ? 'nuveiCheckout-blurred' : ''} style={{justifyContent:"center",textAlign:"center"}}></div>
              <p
                style={{
                  color: "white",
                  fontSize: "12px",
                //   fontFamily: "sans-serif",
                  textAlign: "center",
                }}
              >
                &copy;2024 SimpliCapital ®.All Rights Reserved
              </p>
            </div>
                        </div>

                        <div
                            style={{

                                width: "35%",
                            }}
                        >
                            {(edit === false) ? <div style={{
                                backgroundColor: "#53515e",
                                border: "1px solid transparent",
                                borderRadius: "5px",
                                padding: "10px"
                            }} >


                                <h2
                                    style={{

                                        color: "#ced1d6",
                                        // fontFamily: "sans-serif",
                                        fontWeight: "bold",
                                        display: "flex",

                                        alignItems: "center",
                                        borderRadius: "7px 7px 0px 0px",
                                        fontSize: "25px",
                                        padding: "10px",
                                    }}
                                >
                                    Payment Amount
                                </h2>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        fontWeight: "800",
                                    }}
                                >
                                    <table style={{width: "100%"}}>
                                        <tr >
                                            <td style={{ color: "lightgrey", textAlign: "left" }}>
                                                Invoice Amount :
                                            </td>
                                            <td style={{ color: "rgb(195,195,195)", fontWeight: "600" ,textAlign:"right"}}> <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={parseFloat(newAmount).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            /> </td>
                                        </tr>

                                        {payment === "credit_card" && customer?.surcharge ? (
                                            <tr style={{ borderBottom: "1px solid grey" }}>
                                                <td
                                                    style={{
                                                        color: "lightgrey",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Processing Fees ({surchargeRate}%) :
                                                </td>
                                                <td style={{ color: "rgb(195,195,195)", fontWeight: "600", textAlign: "right" }}><NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={parseFloat(Surcharge).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            /> </td>
                                            </tr>
                                        ) : (
                                            <tr></tr>
                                        )}
                                        <tr style={{ borderTop: "solid 1px grey" }}>
                                            <td
                                                style={{
                                                    color: "rgb(195,195,195)",
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                }}
                                            >
                                                Total
                                            </td>

                                            {payment === "credit_card" ? (
                                                <td style={{ color: "rgb(195,195,195)", fontWeight: "600",textAlign: "right" }}>
                                                    $
                                                    {updatedAmount
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            ) : (
                                                <td style={{ color: "rgb(195,195,195)", fontWeight: "600" ,textAlign: "right" }}><NumberFormat
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                value={parseFloat(newAmount).toFixed(2)}
                                                displayType="text"
                                                isNumericString={true}
                                                decimalScale={2}
                                              /> </td>
                                            )}
                                        </tr>
                                    </table>
                                </div>
                            </div> : ""}

                            <div style={{
                                backgroundColor: "#53515e",
                                border: "1px solid transparent",
                                borderRadius: "5px",
                                marginTop: "15px",
                                padding: "15px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h4 style={{
                                        color: "#ced1d6", 
                                        fontWeight: "bold", fontSize: "18px"
                                    }}>Billing Address</h4>
                                    <svg onClick={handleEdit} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ced1d6" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                    </svg>
                                </div>
                                {
                                    (edit === false) ? <div>


                                        <p style={{
                                            color: "#ced1d6", 
                                            fontSize: "15px", marginBottom: "auto",
                                        }} >

                                            {billingAddr.address}{billingAddr.city} {billingAddr.address || billingAddr.city ? "," : ""} {billingAddr.state} {billingAddr.state ? "," : ""}  {billingAddr.zip}

                                        </p>

                                    </div>
                                        :
                                        <div>
                                            <Form
                                                className="modal_form"
                                                style={{ color: "white" }}
                                                onSubmit={handleSubmit}
                                                noValidate validated={validated}

                                            >
                                                <Row >
                                                    <Col xs={12}>
                                                        <Form.Group className="mb-3" controlId="givenName">
                                                            <Form.Label>FirstName</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}
                                                                required
                                                                className="modal_form_label"
                                                                type="text"

                                                                value={billingAddr.givenName}


                                                                placeholder="Given Name"
                                                                onChange={handleChange}

                                                            />

                                                            {errors.givenName && <Form.Text className="text-danger">{errors.givenName}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Form.Group className="mb-3" controlId="familyName">
                                                            <Form.Label>LastName</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}

                                                                required
                                                                className="modal_form_label"
                                                                type="text"
                                                                value={billingAddr.familyName}


                                                                placeholder="Family Name"
                                                                onChange={handleChange}
                                                            />

                                                            {errors.familyName && <Form.Text className="text-danger">{errors.familyName}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="email">
                                                            <Form.Label>Email</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}

                                                                required
                                                                className="modal_form_label"
                                                                type="email"
                                                                name="email"
                                                                value={billingAddr.email}


                                                                placeholder="Email"
                                                                onChange={handleChange}
                                                                // disabled="true"



                                                            />

                                                            {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="phone">
                                                            <Form.Label>Phone</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}
                                                                required
                                                                className="modal_form_label"

                                                                name="phone"
                                                                maxLength={10}
                                                                minLength={10}
                                                                value={billingAddr.phone}
                                                                placeholder="Phone"
                                                                // type="number"


                                                                onChange={handleChange}

                                                            />

                                                            {errors.phone && <Form.Text className="text-danger">{errors.phone}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row  >
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="address">
                                                            <Form.Label>Address</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}

                                                                required
                                                                className="modal_form_label"
                                                                type="text"
                                                                value={billingAddr.address}


                                                                placeholder="Address"
                                                                onChange={handleChange}
                                                            />

                                                            {errors.address && <Form.Text className="text-danger">{errors.address}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="city">
                                                            <Form.Label>City</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}

                                                                required
                                                                className="modal_form_label"
                                                                type="text"

                                                                value={billingAddr.city}


                                                                placeholder="City"
                                                                onChange={handleChange}
                                                            />

                                                            {errors.city && <Form.Text className="text-danger">{errors.city}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="zip">
                                                            <Form.Label>Zip</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}

                                                                required
                                                                className="modal_form_label"
                                                                type="text"
                                                                name="zip"
                                                                value={billingAddr.zip}


                                                                maxLength={10}
                                                               

                                                                placeholder="Zip Code"
                                                                onChange={handleChange}
                                                                isInvalid={zipError.length > 0}

                                                            />

                                                            {errors.zip && <Form.Text className="text-danger">{errors.zip}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="state">
                                                            <Form.Label>State</Form.Label>

                                                            <Form.Control
                                                                style={{ color: "#fff", backgroundColor: "rgb(59,58,73)" }}
                                                                as="select"

                                                                required
                                                                className="modal_form_label"
                                                                value={billingAddr.state}


                                                                placeholder="State"
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select State</option>

                                                                {state.data.map((stateObj) => (
                                                                    <option key={stateObj.abbreviation} value={stateObj.abbreviation}>
                                                                        {stateObj.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>

                                                            {errors.state && <Form.Text className="text-danger">{errors.state}</Form.Text>}

                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Button variant="primary" style={{ position: "relative", width: "100%", borderRadius: "8px", marginTop: "30px", left: "0px", background: "linear-gradient(270deg, rgb(51, 194, 223) 0%, rgb(0, 129, 197) 100%) 0% 0% no-repeat", border: "none" }} type="submit">
                                                    Submit
                                                </Button>



                                            </Form>
                                        </div>

                                }



                            </div>
                            {(customer.surcharge  && !edit) && <p style={{    /* width: 100%; */
    textAlign: "left",
    color: "azure",
    // position: "absolute",
    bottom: "37px",
    fontSize:"14px",
    padding:payment === "credit_card"?"89px 10px 10px 0px":"128px 10px 10px 0px"}}>
              We add a {surchargeRate}% Processing Fee on all credit card payments. There is no Processing Fee for ACH or debit card payments.
              </p>}

                        </div>
                    </div>
                    : isPaid ?

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            marginTop: "20%"
                        }}><span style={{ marginBottom: "54px" }}>
                                <img src={Logo} alt="simplicapital" />
                            </span>
                            <span style={{ color: "#fff", marginLeft: "14px" }}>
                                This Invoice Is Already Paid.

                            </span>
                        </div>
                        // : expire == true? <span style={{ color: "#fff"}}>
                        //     This token has expired. Please return to your email and click the 'Pay Now' button again to proceed.
                        // </span>
                        : <Spinner animation="border" size="l" className="me-1" style={{ color: "#fff", margin: "20% 0" }} />}



            </div>

            {/* <Modal
          show={beforeExpire}
          onHide={() => setBeforeExpire(false)}
          centered
          style={{}}
        >
          <StyledModal>
            <div className="logout-modal">
              <Modal.Body style={{ padding: "15px" }}>
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "150px" }}
                    src="https://allassets.s3.amazonaws.com/stillthere2-a34f5b3.svg"
                  />
                </div>
                <p style={{ color: "rgb(144, 144, 144)", textAlign: "center" }}>
                 Session Expire Alert!
                  <br />
                  This token will expire soon. Please close this tab, return to your email, and click the 'Pay Now' button again to proceed.
                </p>
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                  <BackStyledButton
                    style={{
                      color: "#fff",
                      borderRadius: "6px",
                      padding: "9px 20px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                        setBeforeExpire(false);
                        
                    }}
                  >
                    {" "}
                    OK
                  </BackStyledButton>
                </div>
              </Modal.Body>
            </div>
          </StyledModal>
        </Modal> */}
        </>



    )
}